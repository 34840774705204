export function EHR() {
    return (
        <div className="text-tag">
            <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9607 4.43214L7.71071 0.182143C7.58929 0.0607143 7.46786 0 7.28571 0H1.21429C0.546429 0 0 0.546429 0 1.21429V15.7857C0 16.4536 0.546429 17 1.21429 17H10.9286C11.5964 17 12.1429 16.4536 12.1429 15.7857V4.85714C12.1429 4.675 12.0821 4.55357 11.9607 4.43214ZM7.28571 1.45714L10.6857 4.85714H7.28571V1.45714ZM10.9286 15.7857H1.21429V1.21429H6.07143V4.85714C6.07143 5.525 6.61786 6.07143 7.28571 6.07143H10.9286V15.7857Z" fill="#ffffff" />
            </svg>
            <div className="tag-text">EHR</div>
        </div>
    )
}

export function NationalSystems() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/api-connect-images/National-System-icon.png" />
            <div className="tag-text">National System</div>
        </div> 
    )
}

export function OS() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/api-connect-images/National-System-icon.png" />
            <div className="tag-text">OS</div>
        </div> 
    )
}

export function NationalSystemIcon () {
    return <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M273 936v-73l47-47H140q-24 0-42-18t-18-42V276q0-24 18-42t42-18h680q24 0 42 18t18 42v480q0 24-18 42t-42 18H640l48 47v73H273ZM140 756h680V276H140v480Zm0 0V276v480Z"/></svg>    
}

export function EhrIcon() {
    return (
        <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9607 4.43214L7.71071 0.182143C7.58929 0.0607143 7.46786 0 7.28571 0H1.21429C0.546429 0 0 0.546429 0 1.21429V15.7857C0 16.4536 0.546429 17 1.21429 17H10.9286C11.5964 17 12.1429 16.4536 12.1429 15.7857V4.85714C12.1429 4.675 12.0821 4.55357 11.9607 4.43214ZM7.28571 1.45714L10.6857 4.85714H7.28571V1.45714ZM10.9286 15.7857H1.21429V1.21429H6.07143V4.85714C6.07143 5.525 6.61786 6.07143 7.28571 6.07143H10.9286V15.7857Z" fill="#ffffff" />
        </svg>
    )
}

export function Hospitals() {
    return (
        <div className="text-tag">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9 17.1C4.52655 17.1 0.9 13.4734 0.9 9C0.9 4.52655 4.52655 0.9 9 0.9C13.4734 0.9 17.1 4.52655 17.1 9C17.1 13.4734 13.4734 17.1 9 17.1ZM0 9C0 4.0293 4.0293 0 9 0C13.9707 0 18 4.0293 18 9C18 13.9707 13.9707 18 9 18C4.0293 18 0 13.9707 0 9ZM7.19595 13.0716V9.9216H10.7959V13.0716H12.5959V4.9716H10.7959V8.1216H7.19595V4.9716H5.39595V13.0716H7.19595Z" fill="#ffffff" />
            </svg>
            <div className="tag-text">Hospitals</div>
        </div>
    )
}

export function HospitalIcon() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9 17.1C4.52655 17.1 0.9 13.4734 0.9 9C0.9 4.52655 4.52655 0.9 9 0.9C13.4734 0.9 17.1 4.52655 17.1 9C17.1 13.4734 13.4734 17.1 9 17.1ZM0 9C0 4.0293 4.0293 0 9 0C13.9707 0 18 4.0293 18 9C18 13.9707 13.9707 18 9 18C4.0293 18 0 13.9707 0 9ZM7.19595 13.0716V9.9216H10.7959V13.0716H12.5959V4.9716H10.7959V8.1216H7.19595V4.9716H5.39595V13.0716H7.19595Z" fill="#ffffff" />
        </svg>
    )
}

export function Clinicians() {
    return (
        <div className="text-tag">
            <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 0V1.21429H13.75V4.85714C13.75 5.50124 13.4866 6.11896 13.0178 6.5744C12.5489 7.02985 11.913 7.28571 11.25 7.28571C10.587 7.28571 9.95108 7.02985 9.48224 6.5744C9.0134 6.11896 8.75 5.50124 8.75 4.85714V1.21429H10V0H7.5V4.85714C7.50152 5.71722 7.816 6.54909 8.38785 7.20571C8.95971 7.86233 9.75211 8.30141 10.625 8.44536V12.1429C10.625 13.109 10.2299 14.0356 9.52665 14.7187C8.82339 15.4019 7.86957 15.7857 6.875 15.7857C5.88044 15.7857 4.92661 15.4019 4.22335 14.7187C3.52009 14.0356 3.125 13.109 3.125 12.1429V8.41379C3.71417 8.26601 4.22762 7.91458 4.56913 7.42535C4.91064 6.93613 5.05675 6.34271 4.98008 5.75632C4.90341 5.16993 4.60922 4.63084 4.15265 4.24009C3.69608 3.84934 3.10849 3.63376 2.50001 3.63376C1.89152 3.63376 1.30393 3.84934 0.84736 4.24009C0.390794 4.63084 0.0966033 5.16993 0.0199318 5.75632C-0.0567397 6.34271 0.0893722 6.93613 0.43088 7.42535C0.772387 7.91458 1.28584 8.26601 1.87501 8.41379V12.1429C1.87501 13.4311 2.40179 14.6665 3.33947 15.5774C4.27715 16.4883 5.54892 17 6.875 17C8.20109 17 9.47285 16.4883 10.4105 15.5774C11.3482 14.6665 11.875 13.4311 11.875 12.1429V8.44536C12.7479 8.30141 13.5403 7.86233 14.1121 7.20571C14.684 6.54909 14.9985 5.71722 15 4.85714V0H12.5ZM1.25001 6.07143C1.25001 5.83127 1.32332 5.5965 1.46067 5.39681C1.59802 5.19712 1.79324 5.04148 2.02165 4.94957C2.25006 4.85767 2.50139 4.83362 2.74387 4.88048C2.98634 4.92733 3.20907 5.04298 3.38389 5.2128C3.5587 5.38262 3.67775 5.59898 3.72599 5.83453C3.77422 6.07008 3.74946 6.31423 3.65485 6.53612C3.56024 6.758 3.40003 6.94764 3.19447 7.08107C2.98891 7.2145 2.74723 7.28571 2.50001 7.28571C2.16859 7.28539 1.85084 7.15736 1.61649 6.9297C1.38214 6.70205 1.25034 6.39338 1.25001 6.07143Z" fill="#ffffff" />
            </svg>

            <div className="tag-text">Clinicians</div>
        </div>
    )
}

export function CliniciansIcon() {
    return (
        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 0V1.21429H13.75V4.85714C13.75 5.50124 13.4866 6.11896 13.0178 6.5744C12.5489 7.02985 11.913 7.28571 11.25 7.28571C10.587 7.28571 9.95108 7.02985 9.48224 6.5744C9.0134 6.11896 8.75 5.50124 8.75 4.85714V1.21429H10V0H7.5V4.85714C7.50152 5.71722 7.816 6.54909 8.38785 7.20571C8.95971 7.86233 9.75211 8.30141 10.625 8.44536V12.1429C10.625 13.109 10.2299 14.0356 9.52665 14.7187C8.82339 15.4019 7.86957 15.7857 6.875 15.7857C5.88044 15.7857 4.92661 15.4019 4.22335 14.7187C3.52009 14.0356 3.125 13.109 3.125 12.1429V8.41379C3.71417 8.26601 4.22762 7.91458 4.56913 7.42535C4.91064 6.93613 5.05675 6.34271 4.98008 5.75632C4.90341 5.16993 4.60922 4.63084 4.15265 4.24009C3.69608 3.84934 3.10849 3.63376 2.50001 3.63376C1.89152 3.63376 1.30393 3.84934 0.84736 4.24009C0.390794 4.63084 0.0966033 5.16993 0.0199318 5.75632C-0.0567397 6.34271 0.0893722 6.93613 0.43088 7.42535C0.772387 7.91458 1.28584 8.26601 1.87501 8.41379V12.1429C1.87501 13.4311 2.40179 14.6665 3.33947 15.5774C4.27715 16.4883 5.54892 17 6.875 17C8.20109 17 9.47285 16.4883 10.4105 15.5774C11.3482 14.6665 11.875 13.4311 11.875 12.1429V8.44536C12.7479 8.30141 13.5403 7.86233 14.1121 7.20571C14.684 6.54909 14.9985 5.71722 15 4.85714V0H12.5ZM1.25001 6.07143C1.25001 5.83127 1.32332 5.5965 1.46067 5.39681C1.59802 5.19712 1.79324 5.04148 2.02165 4.94957C2.25006 4.85767 2.50139 4.83362 2.74387 4.88048C2.98634 4.92733 3.20907 5.04298 3.38389 5.2128C3.5587 5.38262 3.67775 5.59898 3.72599 5.83453C3.77422 6.07008 3.74946 6.31423 3.65485 6.53612C3.56024 6.758 3.40003 6.94764 3.19447 7.08107C2.98891 7.2145 2.74723 7.28571 2.50001 7.28571C2.16859 7.28539 1.85084 7.15736 1.61649 6.9297C1.38214 6.70205 1.25034 6.39338 1.25001 6.07143Z" fill="#ffffff" />
        </svg>
    )
}

export function Workflow() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/api-connect-images/App-icon.png" />
            <div className="tag-text">Workflow Management</div>
        </div>
    )
}

export function Platform() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/api-connect-images/App-icon.png" />
            <div className="tag-text">Platform</div>
        </div>
    )
}

export function EPR() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/api-connect-images/App-icon.png" />
            <div className="tag-text">EPR</div>
        </div>
    )
}

export function IntegratedCares() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/IntegratedCares.png" />
            <div className="tag-text">Integrated Care</div>
        </div>
    )
}
export function Integration() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/IntegratedCares.png" />
            <div className="tag-text">Integration</div>
        </div>
    )
}

export function App() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/api-connect-images/App-icon.png" />
            <div className="tag-text">App</div>
        </div>
    )
}

export function AppIcon () {
    return <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20">
        <path d="M226 896q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226 642q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226 388q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"/>
        </svg>
}

export function EMR() {
    return (
        <div className="text-tag">
            <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9607 4.43214L7.71071 0.182143C7.58929 0.0607143 7.46786 0 7.28571 0H1.21429C0.546429 0 0 0.546429 0 1.21429V15.7857C0 16.4536 0.546429 17 1.21429 17H10.9286C11.5964 17 12.1429 16.4536 12.1429 15.7857V4.85714C12.1429 4.675 12.0821 4.55357 11.9607 4.43214ZM7.28571 1.45714L10.6857 4.85714H7.28571V1.45714ZM10.9286 15.7857H1.21429V1.21429H6.07143V4.85714C6.07143 5.525 6.61786 6.07143 7.28571 6.07143H10.9286V15.7857Z" fill="#ffffff" />
            </svg>
            <div className="tag-text">EMR</div>
        </div>
    )
}

export function FHIR() {
    return (
        <div className="text-tag">
            <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9607 4.43214L7.71071 0.182143C7.58929 0.0607143 7.46786 0 7.28571 0H1.21429C0.546429 0 0 0.546429 0 1.21429V15.7857C0 16.4536 0.546429 17 1.21429 17H10.9286C11.5964 17 12.1429 16.4536 12.1429 15.7857V4.85714C12.1429 4.675 12.0821 4.55357 11.9607 4.43214ZM7.28571 1.45714L10.6857 4.85714H7.28571V1.45714ZM10.9286 15.7857H1.21429V1.21429H6.07143V4.85714C6.07143 5.525 6.61786 6.07143 7.28571 6.07143H10.9286V15.7857Z" fill="#ffffff" />
            </svg>
            <div className="tag-text">FHIR</div>
        </div>
    )
}

export function SecondaryCare() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/api-connect-images/Secondary-Care-icon.png" />
            <div className="tag-text">Secondary Care</div>
        </div>
    )
}

export function SecondaryCareIcon () {
    return <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M290 216q57 0 105.5 27t84.5 78q42-54 89-79.5T670 216q89 0 149.5 60.5T880 427q0 15-1.5 29.5T873 486h-62q5-15 7-29.5t2-29.5q0-66-42-108.5T670 276q-51 0-95 31.5T504 396h-49q-26-56-70-88t-95-32q-66 0-108 42.5T140 427q0 15 2 29.5t7 29.5H87q-4-15-5.5-29.5T80 427q0-90 60.5-150.5T290 216Zm-98 450h78q38 42 89.5 91.5T480 870q69-63 120-112.5t89-91.5h79q-42 49-102 108.5T521 912l-41 37-41-37q-85-78-145-137.5T192 666Zm250 60q9 0 16-5.5t10-14.5l61-181 46 68q5 6 11 9.5t14 3.5h310v-60H617l-72-106q-5-7-12-10t-15-3q-9 0-16.5 5.5T491 447l-60 181-47-69q-5-6-10.5-9.5T360 546H50v60h292l72 107q5 7 12.5 10t15.5 3Zm38-153Z"/></svg>
}

export function PrimaryCare() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/api-connect-images/Primary-Care-icon.png" />
            <div className="tag-text">Primary Care</div>
        </div>
    )
}


export function Locator() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/api-connect-images/Primary-Care-icon.png" />
            <div className="tag-text">Locator</div>
        </div>
    )
}


export function Mobile() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/api-connect-images/Mobile-icon.png" />
            <div className="tag-text">Mobile</div>
        </div>
    )
}

export function Patients() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/api-connect-images/patient-icon.png" />
            <div className="tag-text">Patients</div>
        </div>
    )
}

export function PatientsIcon () {
    return <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M480 575q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM160 896v-94q0-38 19-65t49-41q67-30 128.5-45T480 636q62 0 123 15.5t127.921 44.694q31.301 14.126 50.19 40.966Q800 764 800 802v94H160Zm60-60h520v-34q0-16-9.5-30.5T707 750q-64-31-117-42.5T480 696q-57 0-111 11.5T252 750q-14 7-23 21.5t-9 30.5v34Zm260-321q39 0 64.5-25.5T570 425q0-39-25.5-64.5T480 335q-39 0-64.5 25.5T390 425q0 39 25.5 64.5T480 515Zm0-90Zm0 411Z"/></svg>    
}

export function CommunityCare() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/api-connect-images/Community-Care-icon.png" />
            <div className="tag-text">Community Care</div>
        </div>
    )
}

export function ChildHealth() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/api-connect-images/Community-Care-icon.png" />
            <div className="tag-text">Child Health</div>
        </div>
    )
}

export function Medicare() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/medicare.png" />
            <div className="tag-text">Medicare</div>
        </div>
    )
}

export function MedicalImaging() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/MedicalImagings.png" />
            <div className="tag-text">Medical Imaging</div>
        </div>
    )
}

export function CareRecord() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/MedicalImagings.png" />
            <div className="tag-text">Care Record</div>
        </div>
    )
}

export function Diagnostics() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/diagnostics.png" />
            <div className="tag-text">Diagnostics</div>
        </div>
    )
}

export function Diagnosis() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/diagnostics.png" />
            <div className="tag-text">Diagnosis</div>
        </div>
    )
}

export function Fitness() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/fitness.png" />
            <div className="tag-text">Fitness</div>
        </div>
    )
}

export function Medicene() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/fitness.png" />
            <div className="tag-text">Medicene</div>
        </div>
    )
}

export function DataAnalytics() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/Data-Analytics-logo-1.svg" />
            <div className="tag-text">Data & Analytics</div>
        </div>
    )
}

export function Reviews() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/Data-Analytics-logo-1.svg" />
            <div className="tag-text">Reviews</div>
        </div>
    )
}


export function Telehealth() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/telehealth.png" />
            <div className="tag-text">Telehealth</div>
        </div>
    )
}

export function HealthData() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/telehealth.png" />
            <div className="tag-text">Health Data</div>
        </div>
    )
}

export function MentalHealth() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/telehealth.png" />
            <div className="tag-text">Mental Health</div>
        </div>
    )
}


export function Appointment() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/appointment.png" />
            <div className="tag-text">Appointment</div>
        </div>
    )
}

export function Reference() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/appointment.png" />
            <div className="tag-text">Reference</div>
        </div>
    )
}

export function Pharmacy() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/pharmacy.png" />
            <div className="tag-text">Pharmacy</div>
        </div>
    )
}

export function Metadata() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/metadata.png" />
            <div className="tag-text">Metadata</div>
        </div>
    )
}

export function Research() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/research.png" />
            <div className="tag-text">Research</div>
        </div>
    )
}

export function SocialCare() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/research.png" />
            <div className="tag-text">Social Care</div>
        </div>
    )
}

export function Spine() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://www.alphalake.ai/hubfs/API%20Direct%20Logo/metadata.png" />
            <div className="tag-text">Spine</div>
        </div>
    )
}

export function Insurance() {
    return (
        <div className="text-tag">
            <img className="tag-Image" alt="icon logo" src="https://6637851.fs1.hubspotusercontent-na1.net/hubfs/6637851/Insurance%20Icon.png" />
            <div className="tag-text">Insurance</div>
        </div>
    )
}

export function Cloud() {
    return (
        <div className="text-tag">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-cloud" viewBox="0 0 16 16">
                <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
            </svg>
            <div className="tag-text">Cloud</div>
        </div>
    )
}

export function CloudBased() {
    return (
        <div className="text-tag">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-cloud" viewBox="0 0 16 16">
                <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
            </svg>
            <div className="tag-text">Cloud Based</div>
        </div>
    )
}

export function Developer() {
    return (
        <div className="text-tag">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-code-slash" viewBox="0 0 16 16">
                <path d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294l4-13zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z" />
            </svg>
            <div className="tag-text">Developer</div>
        </div>
    )
}

export function PHR() {
    return (
        <div className="text-tag">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-person-check-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>
            <div className="tag-text">PHR</div>
        </div>
    )
}

export function Wearables() {
    return (
        <div className="text-tag">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-watch" viewBox="0 0 16 16">
                <path d="M8.5 5a.5.5 0 0 0-1 0v2.5H6a.5.5 0 0 0 0 1h2a.5.5 0 0 0 .5-.5V5z" />
                <path d="M5.667 16C4.747 16 4 15.254 4 14.333v-1.86A5.985 5.985 0 0 1 2 8c0-1.777.772-3.374 2-4.472V1.667C4 .747 4.746 0 5.667 0h4.666C11.253 0 12 .746 12 1.667v1.86a5.99 5.99 0 0 1 1.918 3.48.502.502 0 0 1 .582.493v1a.5.5 0 0 1-.582.493A5.99 5.99 0 0 1 12 12.473v1.86c0 .92-.746 1.667-1.667 1.667H5.667zM13 8A5 5 0 1 0 3 8a5 5 0 0 0 10 0z" />
            </svg>
            <div className="tag-text">Wearables</div>
        </div>
    )
}

export function NLP() {
    return (
        <div className="text-tag">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-translate" viewBox="0 0 16 16">
                <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />
                <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" />
            </svg>
            <div className="tag-text">NLP</div>
        </div>
    )
}

export function Medicines() {
    return (
        <div className="text-tag">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-capsule" viewBox="0 0 16 16">
                <path d="M1.828 8.9 8.9 1.827a4 4 0 1 1 5.657 5.657l-7.07 7.071A4 4 0 1 1 1.827 8.9Zm9.128.771 2.893-2.893a3 3 0 1 0-4.243-4.242L6.713 5.429l4.243 4.242Z" />
            </svg>
            <div className="tag-text">Medicines</div>
        </div>
    )
}
export function Database() {
    return (
        <div className="text-tag">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-database" viewBox="0 0 16 16">
                <path d="M4.318 2.687C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4c0-.374.356-.875 1.318-1.313ZM13 5.698V7c0 .374-.356.875-1.318 1.313C10.766 8.729 9.464 9 8 9s-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 5.698ZM14 4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13V4Zm-1 4.698V10c0 .374-.356.875-1.318 1.313C10.766 11.729 9.464 12 8 12s-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 8.698Zm0 3V13c0 .374-.356.875-1.318 1.313C10.766 14.729 9.464 15 8 15s-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13s3.022-.289 4.096-.777c.324-.147.633-.323.904-.525Z" />
            </svg>
            <div className="tag-text">Database</div>
        </div>
    )
}

export function Automation() {
    return (
        <div className="text-tag">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-robot" viewBox="0 0 16 16">
                <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5ZM3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.58 26.58 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.933.933 0 0 1-.765.935c-.845.147-2.34.346-4.235.346-1.895 0-3.39-.2-4.235-.346A.933.933 0 0 1 3 9.219V8.062Zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a24.767 24.767 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25.286 25.286 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135Z" />
                <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2V1.866ZM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5Z" />
            </svg>
            <div className="tag-text">Automation</div>
        </div>
    )
}

export function CX() {
    return (
        <div className="text-tag">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-award" viewBox="0 0 16 16">
  <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z"/>
  <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
</svg>
            <div className="tag-text">CX</div>
        </div>
    )
}

export default function TextTagRenderer({ tag }) {
    switch (tag) {
        case "EHR": return <EHR />;
        case "HOSPITALS": return <Hospitals />;
        case "HOSPITAL": return <Hospitals />;
        case "CLINICIANS": return <Clinicians />;
        case "CLINICIAN": return <Clinicians />;
        case "WORKFLOW": return <Workflow />;
        case "EPR": return <EPR />;
        case "INTEGRATED-CARES": return <IntegratedCares />;
        case "INTEGRATED-CARE": return <IntegratedCares />;
        case "INTEGRATEDCARES": return <IntegratedCares />;
        case "INTEGRATEDCARE": return <IntegratedCares />;
        case "NATIONALSYSTEM": return <NationalSystems />;
        case "NATIONAL-SYSTEM": return <NationalSystems />;
        case "NATIONAL SYSTEM": return <NationalSystems />;
        case "NATIONALSYSTEMS": return <NationalSystems />;
        case "NATIONAL-SYSTEMS": return <NationalSystems />;
        case "APP": return <App />;
        case "SECONDARYCARE": return <SecondaryCare />;
        case "MOBILE": return <Mobile />;
        case "PRIMARYCARE": return <PrimaryCare/>;
        case "EMR": return <EMR/>;
        case "PATIENTS": return <Patients/>;
        case "PLATFORM": return <Platform/>;
        case "PATIENT": return <Patients/>;
        case "COMMUNITYCARE": return <CommunityCare/>;
        case "COMMUNITY CARE": return <CommunityCare/>;
        case "MEDICARE": return <Medicare/>;
        case "MEDICALIMAGING": return <MedicalImaging/>;
        case "MEDICAL IMAGING": return <MedicalImaging/>;
        case "DIAGNOSTICS": return <Diagnostics/>;
        case "DIAGNOSTIC": return <Diagnostics/>;
        case "FITNESS": return <Fitness/>;
        case "DATAANALYTICS": return <DataAnalytics/>;
        case "DATA-ANALYTICS": return <DataAnalytics/>;
        case "DATA & ANALYTICS": return <DataAnalytics/>;
        case "DATA ANALYTICS": return <DataAnalytics/>;
        case "ANALYTICS": return <DataAnalytics/>;
        case "TELEHEALTH": return <Telehealth/>;
        case "APPOINTMENT": return <Appointment/>;
        case "PHARMACY": return <Pharmacy/>;
        case "METADATA": return <Metadata/>;
        case "RESEARCH": return <Research/>;
        case "SOCIAL CARE": return <SocialCare/>;
        case "SPINE": return <Spine/>;
        case "NHS SPINE": return <Spine/>;
        case "MEDICENE": return <Medicene/>;
        case "REFERENCE": return <Reference/>;
        case "HEALTH DATA": return <HealthData/>;
        case "MENTAL HEALTH": return <MentalHealth/>;
        case "REVIEWS": return <Reviews/>;
        case "LOCATOR": return <Locator/>;
        case "CARE RECORD": return <CareRecord/>;
        case "CHILD HEALTH": return <ChildHealth/>;
        case "INSURANCE": return <Insurance />;
        case "OS": return <OS/>;
        case "CLOUD": return <Cloud/>;
        case "DEVELOPER": return <Developer/>;
        case "PHR": return <PHR/>;
        case "WEARABLES": return <Wearables />;
        case "NLP": return <NLP/>;
        case "DIAGNOSIS": return <Diagnosis/>;
        case "CLOUDBASED": return <CloudBased />;
        case "MEDICINES": return <Medicines/>;
        case "DATABASE": return <Database/>;
        case "AUTOAMTION": return <Automation/>;
        case "AUTOMATION": return <Automation/>;
        case "INTEGRATION": return <Integration />;
        case "CX": return <CX/>;
        default: return <></>;
    }
}

// TypeMismatchError: Expected 'number' found 'string';


export function GridIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_1238_6587" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1238_6587)">
                <path d="M3 11V3H11V11H3ZM3 21V13H11V21H3ZM13 11V3H21V11H13ZM13 21V13H21V21H13ZM5 9H9V5H5V9ZM15 9H19V5H15V9ZM15 19H19V15H15V19ZM5 19H9V15H5V19Z" fill="#CCD1D3" />
            </g>
        </svg>
    )
}

export function ListIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_1238_6948" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#CCD1D3" />
            </mask>
            <g mask="url(#mask0_1238_6948)">
                <path d="M4.5 19.5C4.08333 19.5 3.72933 19.354 3.438 19.062C3.146 18.7707 3 18.4167 3 18C3 17.5833 3.146 17.2293 3.438 16.938C3.72933 16.646 4.08333 16.5 4.5 16.5C4.91667 16.5 5.27067 16.646 5.562 16.938C5.854 17.2293 6 17.5833 6 18C6 18.4167 5.854 18.7707 5.562 19.062C5.27067 19.354 4.91667 19.5 4.5 19.5ZM8 19V17H21V19H8ZM4.5 13.5C4.08333 13.5 3.72933 13.354 3.438 13.062C3.146 12.7707 3 12.4167 3 12C3 11.5833 3.146 11.2293 3.438 10.938C3.72933 10.646 4.08333 10.5 4.5 10.5C4.91667 10.5 5.27067 10.646 5.562 10.938C5.854 11.2293 6 11.5833 6 12C6 12.4167 5.854 12.7707 5.562 13.062C5.27067 13.354 4.91667 13.5 4.5 13.5ZM8 13V11H21V13H8ZM4.5 7.5C4.08333 7.5 3.72933 7.354 3.438 7.062C3.146 6.77067 3 6.41667 3 6C3 5.58333 3.146 5.22933 3.438 4.938C3.72933 4.646 4.08333 4.5 4.5 4.5C4.91667 4.5 5.27067 4.646 5.562 4.938C5.854 5.22933 6 5.58333 6 6C6 6.41667 5.854 6.77067 5.562 7.062C5.27067 7.354 4.91667 7.5 4.5 7.5ZM8 7V5H21V7H8Z" fill="#CCD1D3" />
            </g>
        </svg>
    )
}


